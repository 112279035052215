<template>
  <router-view :rs459="rs459" />
</template>

<script>
// @ is an alias to /src
import { Rs459 } from "@/js/Rs459.js";

export default {
  name: "Alertdevice2d",
  data() {
    return {
      rs459: new Rs459(),
    };
  },
  mounted() {
    this.rs459.initialize();
  },
  components: {},
};
</script>
