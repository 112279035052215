<template>
  <DyBackground />
  <div>トラブルパレート図</div>
  <Menu />

  <transition name="yyrslideanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="frame">
        <div style="width: 100%; overflow: auto">
          <div class="linetable">
            <table border="0">
              <thead>
                <tr>
                  <th>ライン呼称</th>
                  <th colspan="2">ステータス</th>
                  <th>生産品種</th>
                  <th>賞味期限</th>
                  <th>指図本数</th>
                  <th>生産本数</th>
                  <th>ケース数</th>
                  <th>生産開始時刻</th>
                  <th>調整停止時間</th>
                  <th>生産終了時刻</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!page.isLineNetwork">
                  <td colspan="11"><SpinLoader /></td>
                </tr>
                <template
                  v-for="(line, index) in page.lineHolder.balls"
                  :key="index"
                >
                  <tr>
                    <td class="linetable_linename">
                      {{ line.raw.fline_name }}
                    </td>
                    <td
                      class="linetable_linestatus"
                      v-bind:class="[
                        line.raw.isalert
                          ? 'linetable_linestatus__danger'
                          : 'linetable_linestatus__success',
                      ]"
                    >
                      {{ status2name(line.raw.adjuststatus) }}
                    </td>
                    <td
                      class="linetable_linestatus"
                      v-bind:class="[
                        line.raw.isalert
                          ? 'linetable_linestatus__danger'
                          : 'linetable_linestatus__success',
                      ]"
                    >
                      {{ line.raw.isalert ? "異常" : "正常" }}
                    </td>
                    <td class="linetable_linekind">
                      {{ line.raw.plan.brand_name }}
                    </td>
                    <td>{{ ymdhms2md(line.raw.plan.plan_expiry_date) }}</td>
                    <td>{{ comma(line.raw.plan.plan_target) }}</td>
                    <td>
                      {{ comma(line.raw.calc.total_production_quantity) }}
                    </td>
                    <td>
                      {{
                        comma(ccalc(line.raw.calc.total_production_quantity))
                      }}
                    </td>
                    <td>{{ ymdhms2hms(line.raw.plan.plan_start_time) }}</td>
                    <td>{{ sec2hms(line.raw.adjustment) }}</td>
                    <td>{{ ymdhms2hms(line.raw.plan.plan_end_time) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="yslideanimation">
    <div class="fullscreen" style="top: 200px" v-show="isShow">
      <div style="width: 100%; height: 100%; overflow: auto">
        <div class="frame">
          <div class="linetable">
            <table border="0">
              <thead>
                <tr>
                  <th>予定日付</th>
                  <th>予定開始時間</th>
                  <th>予定終了時間</th>
                  <th>生産品種</th>
                  <th>目標</th>
                  <th>生産開始時間</th>
                  <th>生産終了時間</th>
                  <th colspan="4">コントローラ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!page.isPlanNetwork">
                  <td colspan="11"><SpinLoader /></td>
                </tr>
                <template
                  v-for="(plan, index) in page.plansHolder.balls"
                  :key="index"
                >
                  <tr>
                    <td>
                      {{ ymdhms2ymd(plan.raw.plan_start_schedule) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_start_schedule) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_end_schedule) }}
                    </td>
                    <td>{{ plan.raw.brand_name }}</td>
                    <td>
                      {{ comma(plan.raw.plan_target) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_start_time) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_end_time) }}
                    </td>
                    <td class="linetable_link">
                      <div class="linkbtn" v-on:click="start(plan.raw.plan_id)">
                        <i class="fas fa-clipboard-list"></i> 開始
                      </div>
                    </td>
                    <td
                      class="linetable_link"
                      v-on:click="end(plan.raw.plan_id)"
                    >
                      <div class="linkbtn">
                        <i class="fas fa-clipboard-list"></i> 終了
                      </div>
                    </td>
                    <td class="linetable_link">
                      <div
                        class="linkbtn"
                        v-on:click="gotoAdjustment(plan.raw.plan_id)"
                      >
                        <i class="fas fa-clipboard-list"></i> 調整
                      </div>
                    </td>
                    <td class="linetable_link">
                      <div
                        class="linkbtn"
                        v-on:click="deletePlan(plan.raw.plan_id)"
                      >
                        <i class="fas fa-clipboard-list"></i> 削除
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div style="display: flex; margin-top: 16px; margin-bottom: 80px">
            <div
              style="width: 200px; text-align: center"
              class="linkbtn"
              v-on:click="page.inputMode()"
            >
              <i class="fas fa-clipboard-list"></i> 新規計画
            </div>
            <div style="width: 100%"></div>
            <div style="width: 200px">前頁 1 次頁</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div
    class="fullscreen"
    style="background: rgba(0, 0, 0, 0.5)"
    v-if="page.isInputMode()"
  >
    <div class="frame">
      <div class="card" style="max-width: 400px; margin: auto">
        <div class="card_header"><i class="far fa-bell"></i> 計画</div>
        <div class="card_body">
          <div style="margin-top: 8px">
            <div>予定開始時刻</div>
            <div>
              <input
                type="text"
                style="width: 100%; font-size: 20px"
                v-model="page.inputparam.planStartSchedule"
              />
            </div>
          </div>
          <div style="margin-top: 8px">
            <div>予定終了時刻</div>
            <div>
              <input
                type="text"
                style="width: 100%; font-size: 20px"
                v-model="page.inputparam.planEndSchedule"
              />
            </div>
          </div>
          <div style="margin-top: 8px">
            <div>賞味期限</div>
            <div>
              <input
                type="text"
                style="width: 100%; font-size: 20px"
                v-model="page.inputparam.planExpiryDate"
              />
            </div>
          </div>
          <div style="margin-top: 8px">
            <div>生産品種</div>
            <div>
              <select v-model="page.selectedkindoption" style="font-size: 20px">
                <option
                  v-for="(option, index) in page.kindoptions"
                  v-bind:value="option.value"
                  :key="index"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div style="margin-top: 8px">
            <div>目標</div>
            <div>
              <input
                type="number"
                style="width: 100%; font-size: 20px"
                v-model="page.inputparam.planTarget"
              />
            </div>
          </div>
          <div style="display: flex; margin-top: 32px">
            <div
              style="width: 150px; text-align: center"
              class="linkbtn"
              v-on:click="page.viewMode()"
            >
              <i class="fas fa-clipboard-list"></i> キャンセル
            </div>
            <div style="width: 100%"></div>
            <div
              style="width: 150px; text-align: center"
              class="linkbtn"
              v-on:click="page.registplan()"
            >
              <i class="fas fa-clipboard-list"></i> 登録
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="backmenu">
    <div class="backicon" v-on:click="gotoHome()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}

@keyframes downslide {
  from {
    transform: translateY(-800px);
  }
  to {
    transform: translateY(0);
  }
}

.yslideanimation-enter-active {
  animation: yslide 0.2s;
}
.yslideanimation-leave-active {
  animation: yslide 0.2s reverse;
}

@keyframes yslide {
  from {
    transform: translateX(-2000px);
  }
  to {
    transform: translateX(0);
  }
}

.yyrslideanimation-enter-active {
  animation: yrslide 0.2s;
}
.yyrslideanimation-leave-active {
  animation: yyrslide 0.2s;
}

@keyframes yrslide {
  from {
    transform: translateX(2000px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes yyrslide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-2000px);
  }
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import DyBackground from "@/components/DyBackground";

import SpinLoader from "@/components/SpinLoader";

import { PlanPage } from "@/js/page/PlanPage";

export default {
  name: "Plan",
  data() {
    return {
      page: new PlanPage(),
      isShow: false,
    };
  },
  methods: {
    sec2hms(sec) {
      try {
        let ss = Number(sec);
        let h = Math.floor(ss / 60 / 60);
        let m = Math.floor((ss - h * 60 * 60) / 60);
        let s = ss - h * 60 * 60 - m * 60;
        return (
          String(h).padStart(2, "0") +
          ":" +
          String(m).padStart(2, "0") +
          ":" +
          String(s).padStart(2, "0")
        );
      } catch (ex) {
        console.log(ex);
      }
      return "00:00";
    },
    comma(num) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    status2name(id) {
      switch (id) {
        case 0:
          return "生産なし";
        case 1:
          return "生産中";
        case 2:
          return "調整中";
        case 10:
          return "生産終了";
      }
      return;
    },
    gotoHome() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Home" });
      }, 1000 * 0.2);
    },
    gotoBack() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Home" });
      }, 1000 * 0.2);
    },
    gotoAdjustment(planId) {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({
          name: "Adjustment",
          query: { plan_id: planId, fline_id: self.page.flineId },
        });
      }, 1000 * 0.2);
    },
    deletePlan(planId) {
      this.page.deletePlan(planId);
    },
    start(planId) {
      this.page.start(planId);
    },
    end(planId) {
      this.page.end(planId);
    },
    ymdhms2ymd(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        return sps[0];
      }

      return "";
    },
    ymdhms2hms(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        if (sps.length > 1) {
          return sps[1];
        }
      }

      return "";
    },
    ymdhms2md(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        let spss = sps[0].split("-");
        if (spss.length > 1) {
          return spss[1] + "/" + spss[2];
        }
      }

      return "";
    },
    ccalc(count) {
      try {
        return Math.floor(count / 12);
      } catch (ex) {
        console.log(ex);
      }

      return 0;
    },
  },
  mounted() {
    let flineId = this.$route.query.fline_id;
    this.page.initialize(flineId);
    this.isShow = true;
  },
  beforeUnmount() {
    this.page.destroy();
  },
  components: {
    Menu,
    SpinLoader,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
