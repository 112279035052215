<template>
  <div>生産管理：ライン 機器3D</div>
  <Menu />
  <div class="stubbox">
    <div class="stubbox__header">画面遷移</div>
    <div class="stubbox__body">
      <button v-on:click="gotoDevice2d()">2D切替</button>
      <button v-on:click="gotoLine()">ラインへ戻る</button>
      <button v-on:click="gotoPareto()">パレート図</button>
    </div>
  </div>
  <div class="stubbox">
    <div class="stubbox__header">機器情報</div>
    <div class="stubbox__body">-</div>
  </div>
  <div class="stubbox">
    <div class="stubbox__header">アラートリスト</div>
    <div class="stubbox__body">-</div>
  </div>
  <div class="stubbox">
    <div class="stubbox__header">3D画像配列</div>
    <div class="stubbox__body">-</div>
  </div>
  <div class="stubbox">
    <div class="stubbox__header">アラームポイント配列</div>
    <div class="stubbox__body">-</div>
  </div>
  <div class="stubbox">
    <div class="stubbox__header">機器状態</div>
    <div class="stubbox__body">-</div>
  </div>

  <div class="wireframe">
    <img src="@/assets/stub/05.png" />
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";

function Device3DPage() {
  this.id = -1;
}
Device3DPage.prototype.initialize = function (id) {
  this.id = id;
};
Device3DPage.prototype.getLineId = function () {
  return 1;
};

export default {
  name: "Device3d",
  data() {
    return {
      page: new Device3DPage(),
    };
  },
  methods: {
    gotoDevice2d() {
      this.$router.push({ name: "Device2d", query: { id: this.page.id } });
    },
    gotoLine() {
      this.$router.push({ name: "Line", query: { id: this.page.getLineId() } });
    },
    gotoPareto() {
      this.$router.push({ name: "Pareto" });
    },
  },
  mounted() {
    this.page.initialize(this.$route.query.id);
  },
  components: {
    Menu,
  },
  props: {
    rs459: Object,
  },
};
</script>
