<template>
  <div class="bgscreen__img"></div>
  <div class="bgscreen"></div>
</template>

<style scoped>
/*
.bgscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.bgscreen__img {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("/main_img04s.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
*/
</style>

<script>
export default {
  name: "DyBackground",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  components: {},
  props: {},
};
</script>
