
import { Ball, BallsHolder } from "@/js/com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function Device(id, name, x, y, deployId, alert) {
  this.id = id;
  this.name = name;
  this.x = x;
  this.y = y;
  this.deployId = deployId;
  this.alert = alert;
}

function Layout(path, width, height) {
  this.path = path;
  this.width = width;
  this.height = height;
}

function LineLayoutPage(callback = function () { }, acallback = function () { }) {
  this.name = "line";
  this.processId = 0;
  this.flineId = 0;
  this.isDestroy = false;
  this.deviceHolder = new BallsHolder();
  this.devices = [];
  this.callback = callback;
  this.alertHolder = new BallsHolder();
  this.acallback = acallback;
  this.isAlertNetwork = false;
}
LineLayoutPage.prototype.destroy = function () {
  this.isDestroy = true;
};
LineLayoutPage.prototype.initialize = function (processId, flineId) {
  this.isAlertNetwork = false;
  this.processId = processId;
  this.flineId = flineId;
  this.devices = [];
  this.layout = new Layout();
  //this.loadDevices();
  this.initDevices();
  this.loadAlerts();
  this.baseDevice = null;
};
LineLayoutPage.prototype.initDevices = function () {
  let self = this;
  self.devices = [];
  fetch(
    url +
    "/kk459admin/api/v0/lines/deploys?fline_id=" +
    this.flineId +
    "&dummy=" +
    new Date().getTime() /*'stub/lines.json'*/
  )
    .then((response) => response.json())
    .then((json) => {
      self.layout = new Layout(
        json.data.fline_image,
        json.data.fline_image_width,
        json.data.fline_image_height
      );
      for (let i in json.data.deploys) {
        let deploys = json.data.deploys[i];
        if (deploys.process_id == self.processId) {
          self.baseDevice = new Device(
            deploys.id,
            deploys.dposition_name,
            deploys.dposition_x,
            deploys.dposition_y,
            deploys.deploy_id
          );
        }
        self.devices.push(
          new Device(
            deploys.id,
            deploys.dposition_name,
            deploys.dposition_x,
            deploys.dposition_y,
            deploys.deploy_id
          )
        );
      }
      self.callback();
      //console.log(self.devices);
    })
    .catch((error) => {
      console.log(error);
    });
};
LineLayoutPage.prototype.loadDevices = function () {
  let self = this;
  if (this.isDestroy) {
    return;
  }

  fetch(
    url +
    "/kk459admin/api/v0/lines/deploys?fline_id=" +
    this.flineId +
    "&dummy=" +
    new Date().getTime() /*'stub/lines.json'*/
  )
    .then((response) => response.json())
    .then((json) => {
      console.log(json);

      let balls = [];
      for (let i in json.data) {
        balls.push(new Ball(json.data[i]));
      }
      self.deviceHolder.refresh(balls, "id");
      self.loadDeviceLooper();
    })
    .catch((error) => {
      console.log(error);
      self.loadDeviceLooper(10);
    });
};
LineLayoutPage.prototype.loadDeviceLooper = function () {
  let self = this;
  if (this.isDestroy) {
    return;
  }

  setTimeout(function () {
    self.loadDevices();
  }, 1000 * 1);
};
LineLayoutPage.prototype.loadAlerts = function () {
  let self = this;

  if (this.isDestroy) {
    return;
  }

  fetch(
    url +
    "/kk459admin/api/v0/alert/search?fline_id=" +
    this.flineId +
    "&dummy=" +
    new Date().getTime() /*'stub/lines.json'*/
  )
    .then((response) => response.json())
    .then((json) => {
      console.log(json);
      self.isAlertNetwork = true;

      let balls = [];
      for (let i in json.data) {
        balls.push(new Ball(json.data[i]));
      }
      self.alertHolder.refresh(balls, "id");

      self.acallback();
      self.loadAlertLooper();
    })
    .catch((error) => {
      console.log(error);
      self.loadAlertLooper(10);
    });
};
LineLayoutPage.prototype.loadAlertLooper = function (sec = 3) {
  let self = this;
  if (this.isDestroy) {
    return;
  }

  setTimeout(function () {
    self.loadAlerts();
  }, 1000 * sec);
};



export { LineLayoutPage }
