import { Ball, BallsHolder } from "@/js/com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function AdjustmentPage() {
    this.name = "Adjustment";
    this.flineId = 0;
    this.planId = 0;
    this.isDestroy = false;
    this.plansHolder = new BallsHolder();
    this.isPlanNetwork = false;
}
AdjustmentPage.prototype.destroy = function () {
    this.isDestroy = true;
};
AdjustmentPage.prototype.initialize = function (planId, flineId) {
    this.isPlanNetwork = false;
    this.planId = planId;
    this.flineId = flineId;
    this.loadPlans();
};
AdjustmentPage.prototype.loadPlans = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/plans/adjustment?plan_id=" +
        this.planId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;
            self.isPlanNetwork = true;
            let balls = [];
            balls.push(new Ball(json.data));
            self.plansHolder.refresh(balls, "plan_id");
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
        });
};
AdjustmentPage.prototype.regist = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    let starttime = "2001-01-01 00:00:00";
    fetch(
        url +
        "/kk459admin/api/v0/plans/startadjustment?plan_id=" +
        this.planId +
        "&adjustment_start_time=" +
        starttime +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;

            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
        });
};
AdjustmentPage.prototype.endAdjustment = function (adjustmentId) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    let endtime = "2001-01-01 00:00:00";
    fetch(
        url +
        "/kk459admin/api/v0/plans/endadjustment?adjustment_id=" +
        adjustmentId +
        "&adjustment_end_time=" +
        endtime +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;

            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");
            self.loadLineLooper();
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
            self.loadLineLooper(10);
        });
};
AdjustmentPage.prototype.deleteAdjustment = function (adjustmentId) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/plans/deleteadjustment?adjustment_id=" +
        adjustmentId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;

            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");
            self.loadLineLooper();
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
            self.loadLineLooper(10);
        });
};

export { AdjustmentPage }
