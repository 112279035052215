<template>
  <div id="video-area">
    <video
      id="video"
      poster="img/movie.jpg"
      webkit-playsinline
      playsinline
      muted
      autoplay
      loop
    >
      <source
        src="https://shikokukakoki-virtualexhibition.jp/assets/movie/other/introduction_r480b200kan.mp4"
        type="video/mp4"
      />
      <p>動画を再生できる環境ではありません。</p>
    </video>
    <!--/video-area-->
  </div>
  <div class="bgscreen"></div>

  <transition name="fade">
    <div class="fullscreen" v-if="isShow">
      <div class="frame">
        <div style="max-width: 320px; margin: auto">
          <div style="margin-bottom: 16px">
            <img width="320" src="@/booby/assets/img/bep_logo_white.png" />
          </div>
          <div class="card">
            <div class="card_header">
              <i class="fas fa-sign-in-alt"></i> ログイン
            </div>
            <div class="card_body">
              <SpinLoader v-if="false" />

              <div class="input-group">
                <input
                  v-model="username"
                  type="email"
                  class="form-control"
                  placeholder="username"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  placeholder="password"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>

              <div class="input-group">
                <div class="errmessage">{{ message }}</div>
              </div>
              <div class="input-group">
                <div class="linkbtn" style="width: 100%" v-on:click="login">
                  ログイン
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="downanimation">
    <Menu v-show="isLogin" />
  </transition>
</template>

<style scoped>
.input-group {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  margin-top: 24px;
}
/*padding: 0.375rem 0.75rem; */
.form-control {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-right: solid 1px rgba(255, 255, 255, 1);
}

.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control {
  position: relative;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group-append {
  margin-left: -1px;
}
.input-group-append,
.input-group-prepend {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.input-group .input-group-text,
.register-card-body .input-group .input-group-text {
  background-color: transparent;
  border-bottom-right-radius: 0.25rem;
  border-left: 0;
  border-top-right-radius: 0.25rem;
  color: #777;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
}
.input-group-text {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.linkbtn {
  text-align: center;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style scoped>
.bgscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

#video-area {
  position: fixed;
  z-index: -1; /*最背面に設定*/
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

#video {
  /*天地中央配置*/
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*縦横幅指定*/
  width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
  height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
  min-height: 100%;
  min-width: 100%;
}
</style>

<style scoped>
.fullscreen {
  bottom: 45px;
}
</style>

<style scoped>
.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}

@keyframes downslide {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0);
  }
}
</style>

<script>
// @ is an alias to /src
import SpinLoader from "@/components/SpinLoader";
import Menu from "@/components/Menu";

/* global changeWCss */
/* global changeBCss */

export default {
  name: "Login",
  data() {
    return {
      username: "shikoku@blue-i.co.jp",
      password: "",
      message: "",
      isShow: false,
      isLogin: false,
    };
  },
  mounted() {
    let self = this;
    self.isShow = true;
  },
  methods: {
    login() {
      let self = this;
      if (
        (this.username == "shikoku@blue-i.co.jp" ||
          this.username == "user@blue-i.co.jp") &&
        this.password == "kk459"
      ) {
        this.isLogin = true;
        this.isShow = false;
        this.rs459.auth.login(this.username, this.password);
        setTimeout(function () {
          self.$router.push({ name: "Home" });
        }, 1000 * 0.2);
      } else {
        if (this.password == "white") {
          changeWCss();
        }
        if (this.password == "black") {
          changeBCss();
        }
        this.password = "";
        this.message = "ログインエラー";
      }
    },
  },
  components: {
    SpinLoader,
    Menu,
  },
  props: {
    rs459: Object,
  },
};
</script>
