import { Ball, BallsHolder } from "@/js/com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function ParetoPage(callback = function () { }) {
    this.name = "Pareto";
    this.flineId = 0;
    this.targetYmd = "2021-01-01";
    this.devices = [];
    this.lineHolder = new BallsHolder();
    this.isDestroy = false;
    this.paretoHolder = new BallsHolder();
    this.callback = callback;
    this.isLineNetwork = false;
    this.isParetoNetwork = false;
    this.selectedAlertCode = "";
}
ParetoPage.prototype.destroy = function () {
    this.isDestroy = true;
};
ParetoPage.prototype.initialize = function (flineId, targetYmd) {
    this.isLineNetwork = false;
    this.isParetoNetwork = false;
    this.flineId = flineId;
    this.targetYmd = targetYmd;
    this.loadLines();
    this.loadPareto();
};
ParetoPage.prototype.loadLines = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/lines/status?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");

            self.isLineNetwork = true;

            self.loadLineLooper();
        })
        .catch((error) => {
            console.log(error);

            self.loadLineLooper(10);
        });
};
ParetoPage.prototype.loadLineLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadLines();
    }, 1000 * sec);
};

ParetoPage.prototype.loadPareto = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/alert/pareto?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);


            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.paretoHolder.refresh(balls, "id");

            self.isParetoNetwork = true;

            self.callback();
        })
        .catch((error) => {
            console.log(error);

        });
};
ParetoPage.prototype.selectedMessage = function () {
    try {
        for (let key in this.paretoHolder.balls) {
            if (this.paretoHolder.balls[key].raw.code == this.selectedAlertCode) {
                return "[" + this.paretoHolder.balls[key].raw.code + "] " + this.paretoHolder.balls[key].raw.interlock_message.split(":")[1];
            }
        }

    } catch (ex) {
        console.log(ex);
    }
    return "";
};

export { ParetoPage }
