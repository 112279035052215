<template>
  <DyBackground />
  <div>生産管理：ライン レイアウト図</div>
  <Menu />
  <transition name="downanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="frame">
        <div>
          <div class="card card--primary">
            <div class="card_header card_header--primary">
              <i class="far fa-bell"></i> 巡回状態
            </div>
            <div class="card_body">
              <div class="infobox" v-if="true">
                <div class="infobox__up"></div>
                <div class="infobox__msg">
                  <table>
                    <tbody>
                      <tr>
                        <td class="infobox__message_area">
                          <div class="infobox__message_area_title">
                            2022/03/07
                          </div>
                          <div class="infobox__message_area_body">
                            正常動作中
                          </div>
                        </td>
                        <td class="infobox__icon_area1">
                          <i class="fas fa-question"></i>
                        </td>
                        <td class="infobox__icon_area2">
                          <i class="fas fa-check"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="infobox__down"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div
      class="fullscreen"
      style="top: 260px; left: 16px; right: 16px; bottom: 16px"
      v-show="isShow"
    >
      <div style="width: 100; height: 100%">
        <video
          muted
          autoplay
          playsinline
          loop
          ref="fpv"
          src="https://bep-eywa.com/admin459/upload/robot.mp4"
          style="
            width: 100%;
            height: 100%;
            border-radius: 16px;
            height: 100%;
            border: solid 1px rgba(0, 184, 255, 1);
          "
        />
      </div>
    </div>
  </transition>

  <div class="backmenu">
    <div class="backicon" v-on:click="gotoLine()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}

@keyframes downslide {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style scoped>
.infobox__msg {
  height: 128px;
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import DyBackground from "@/components/DyBackground";

export default {
  name: "Robot",
  data() {
    return {
      isShow: false,
      processId: 0,
      flineId: 0,
      deviceId: 0,
    };
  },
  methods: {
    gotoLine() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        if (
          self.flineId == undefined ||
          self.processId == undefined ||
          self.deviceId == undefined
        ) {
          self.$router.push({
            name: "Home",
          });

          return;
        }

        self.$router.push({
          name: "Device2d",
          query: {
            fline_id: self.flineId,
            process_id: self.processId,
            device_id: self.deviceId,
          },
        });
      }, 1000 * 0.2);
    },
  },
  mounted() {
    this.isShow = true;
    this.processId = this.$route.query.process_id;
    this.flineId = this.$route.query.fline_id;
    this.deviceId = this.$route.query.device_id;
  },
  beforeUnmount() {},
  components: {
    Menu,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
