import { Ball, BallsHolder } from "@/js/com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function PlanPage() {
    this.name = "plan";
    this.flineId = 0;
    this.lineHolder = new BallsHolder();
    this.isDestroy = false;
    this.plansHolder = new BallsHolder();
    this.mode = 0;
    this.inputparam = {
        planStartSchedule: "",
        planEndSchedule: "",
        planExpiryDate: "",
        planTarget: "",
    };
    this.kindoptions = [
        { text: "Y1000", value: "1" },
        { text: "Yakult1000", value: "7" },
    ];
    this.selectedkindoption = -1;
    this.isNetwork = false;
    this.isLineNetwork = false;
    this.isPlanNetwork = false;
}
PlanPage.prototype.inputMode = function () {
    this.mode = 1;
};
PlanPage.prototype.viewMode = function () {
    console.log(this.inputparam.planStartSchedule);
    console.log(this.selectedkindoption);

    this.mode = 0;
};
PlanPage.prototype.isInputMode = function () {
    return this.mode == 1;
};
PlanPage.prototype.destroy = function () {
    this.isDestroy = true;
};
PlanPage.prototype.initialize = function (flineId) {
    this.isNetwork = false;
    this.isLineNetwork = false;
    this.isPlanNetwork = false;

    this.flineId = flineId;
    this.loadLines();
    this.loadPlans();
};
PlanPage.prototype.loadPlans = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/plans/search?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isPlanNetwork = true;

            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.plansHolder.refresh(balls, "plan_id");
        })
        .catch((error) => {
            console.log(error);
        });
};
PlanPage.prototype.loadLines = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/lines/status?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isLineNetwork = true;
            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");
            self.loadLineLooper();
        })
        .catch((error) => {
            console.log(error);
            self.loadLineLooper(10);
        });
};
PlanPage.prototype.loadLineLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadLines();
    }, 1000 * sec);
};
PlanPage.prototype.registplan = function () {
    if (this.selectedkindoption < 1) {
        alert("入力値エラー");
        return;
    }
    this.regist(
        this.flineId,
        this.selectedkindoption,
        this.inputparam.planStartSchedule,
        this.inputparam.planEndSchedule,
        this.inputparam.planExpiryDate,
        this.inputparam.planTarget
    );
};
PlanPage.prototype.regist = function (
    flineId,
    blandId,
    planStartSchedule,
    planEndSchedule,
    planExpiryDate,
    planTarget
) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/plans/regist?fline_id=" +
        flineId +
        "&bland_id=" +
        blandId +
        "&plan_start_schedule=" +
        planStartSchedule +
        "&plan_end_schedule=" +
        planEndSchedule +
        "&plan_expiry_date=" +
        planExpiryDate +
        "&plan_target=" +
        planTarget +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;

            self.$router.go({ path: self.$router.currentRoute.path, force: true });
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
        });
};
PlanPage.prototype.deletePlan = function (planId) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/plans/delete?plan_id=" +
        planId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
        });
};
PlanPage.prototype.start = function (planId) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    let starttime = "2021-01-01 00:00:00";
    fetch(
        url +
        "/kk459admin/api/v0/plans/start?plan_id=" +
        planId +
        "&plan_start_time=" +
        starttime +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
        });
};
PlanPage.prototype.end = function (planId) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    let endtime = "2021-01-01 00:00:00";
    fetch(
        url +
        "/kk459admin/api/v0/plans/end?plan_id=" +
        planId +
        "&plan_end_time=" +
        endtime +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            self.isNetwork = false;
        })
        .catch((error) => {
            console.log(error);
            self.isNetwork = false;
        });
};

export { PlanPage }
