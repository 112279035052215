<template>
  <DyBackground />
  <div>生産管理：ライン レイアウト図</div>
  <Menu />
  <transition name="downanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="frame">
        <div>
          <div class="card card--danger">
            <div class="card_header card_header--danger">
              <i class="far fa-bell"></i> アラームリスト
            </div>
            <div class="card_body">
              <SpinLoader v-if="!page.isAlertNetwork" />
              <div class="infobox" v-if="page.isAlertNetwork">
                <div class="infobox__up"></div>
                <div class="infobox__msg">
                  <table style="width: 100%">
                    <tbody>
                      <template
                        v-for="(alert, index) in page.alertHolder.balls"
                        :key="index"
                      >
                        <tr>
                          <td class="infobox__message_area">
                            <div class="infobox__message_area_title">
                              {{ alert.raw.test_start }}
                            </div>
                            <div class="infobox__message_area_body">
                              {{ alert.raw.interlock_message }}
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div class="infobox__down"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div
      class="fullscreen"
      style="top: 260px; left: 16px; right: 16px; bottom: 16px"
      v-show="isShow"
    >
      <div style="width: 100; height: 100%">
        <div id="map"></div>
      </div>
    </div>
  </transition>

  <div class="backmenu">
    <div class="backicon" v-on:click="gotoLine()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}

@keyframes downslide {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style scoped>
.infobox__msg {
  height: 128px;
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import DyBackground from "@/components/DyBackground";

import SpinLoader from "@/components/SpinLoader";

import { LineLayoutPage } from "@/js/page/LineLayoutPage";

/* global L */

export default {
  name: "Line",
  data() {
    let self = this;
    return {
      page: new LineLayoutPage(
        function () {
          self.mapdraw();
          self.markerdraw();
          //process_id
          if (self.page.baseDevice) {
            self.mapMove(self.page.baseDevice.x, self.page.baseDevice.y);
          }
        },
        function () {
          self.markerdraw();
        }
      ),
      vmap: null,
      markerObjects: [],
      lmarkerObjects: [],
      isShow: false,
    };
  },
  methods: {
    gotoHome() {
      this.$router.push({ name: "Home" });
    },
    gotoLine() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({
          name: "Line",
          query: { fline_id: self.page.flineId },
        });
      }, 1000 * 0.2);
    },
    gotoDevice2d(deviceId) {
      this.$router.push({
        name: "Device2d",
        query: {
          device_id: deviceId,
          fline_id: this.page.flineId,
          process_id: this.page.processId,
        },
      });
    },
    markerdraw() {
      if (this.markerObjects) {
        for (let ii in this.markerObjects) {
          let m = this.markerObjects[ii];
          m.setIcon(
            L.divIcon({
              className: "marker--standby",
            })
          );
        }

        for (let i in this.markerObjects) {
          let marker = this.markerObjects[i];
          for (let key in this.page.alertHolder.balls) {
            let alert = this.page.alertHolder.balls[key];
            if (marker.dposition_id == alert.raw.dposition_id) {
              marker.setIcon(
                L.divIcon({
                  className: "marker--danger",
                })
              );
              break;
            }
          }
        }
      }
    },
    mapMove(x, y) {
      this.vmap.setView([y / 10, x / 10], 4);
    },
    mapdraw() {
      let image = {
        url:
          this.rs459.url() +
          "/kk459admin/upload/layout/" +
          this.page.layout.path,
        width: this.page.layout.width,
        height: this.page.layout.height,
      };

      let imageBounds = L.latLngBounds(
        [0, 0],
        [image.height / 10, image.width / 10]
      );

      this.vmap.fitBounds(imageBounds);

      let self = this;
      for (let i in this.page.devices) {
        let device = this.page.devices[i];
        var marker = L.marker([device.y / 10, device.x / 10], {
          icon: L.divIcon({
            className: "marker--standby",
          }),
        });
        console.log(device);

        marker.addTo(this.vmap);
        //let marker = L.marker([device.y / 10, device.x / 10]).addTo(this.vmap);
        marker.on("click", function () {
          console.log(device);
          self.gotoDevice2d(device.deployId);
        });
        marker.dposition_id = device.id;
        this.markerObjects.push(marker);
      }

      this.baseMapObject = L.imageOverlay(image.url, imageBounds).addTo(
        this.vmap
      );
    },
    markerlabelredraw() {
      for (let i in this.page.devices) {
        let device = this.page.devices[i];
        var label = L.divIcon({
          className: "marker__label",
          html: "<p>" + device.name + "</p>",
          iconSize: null,
        });
        var lmarker = L.marker([device.y / 10, device.x / 10], { icon: label });
        lmarker.addTo(this.vmap);
        lmarker.on("click", function () {
          console.log(device);
          self.gotoDevice2d(device.deployId);
        });
        this.lmarkerObjects.push(lmarker);
      }
    },
  },
  mounted() {
    let processId = this.$route.query.process_id;
    let flineId = this.$route.query.fline_id;
    this.page.initialize(processId, flineId);

    let map = L.map("map", {
      crs: L.CRS.Simple,
      maxZoom: 5,
      zoomAnimation: true,
    });
    this.vmap = map;
    this.isShow = true;
    let self = this;
    map.on("zoom", function () {
      console.log(map.getZoom());
      for (let k in self.lmarkerObjects) {
        self.vmap.removeLayer(self.lmarkerObjects[k]);
      }

      if (map.getZoom() > 1) {
        self.markerlabelredraw();
      }
    });
    //this.mapdraw();
  },
  beforeUnmount() {
    if (this.baseMapObject) {
      this.vmap.removeLayer(this.baseMapObject);
    }
    for (let i in this.markerObjects) {
      this.vmap.removeLayer(this.markerObjects[i]);
    }
    for (let k in this.lmarkerObjects) {
      this.vmap.removeLayer(this.lmarkerObjects[k]);
    }

    this.page.destroy();
  },
  components: {
    Menu,
    SpinLoader,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
