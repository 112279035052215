<template>
  <DyBackground />

  <div>トラブルパレート図</div>
  <Menu />

  <transition name="downanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="frame">
        <div class="yoko">
          <div style="width: 100%; overflow: auto">
            <div class="linetable" style="min-width: 900px">
              <table border="0">
                <thead>
                  <tr>
                    <th>ライン呼称</th>
                    <th>所要時間</th>
                    <th>実稼働時間</th>
                    <th>トラブル停止時間</th>
                    <th>調整停止時間</th>
                    <th>稼働率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!page.isLineNetwork">
                    <td colspan="6"><SpinLoader /></td>
                  </tr>
                  <template
                    v-for="(line, index) in page.lineHolder.balls"
                    :key="index"
                  >
                    <tr>
                      <td class="linetable_linename">
                        {{ line.raw.fline_name }}
                      </td>
                      <td>{{ sec2hms(line.raw.calc.total) }}</td>
                      <td>{{ sec2hms(line.raw.calc.total) }}</td>
                      <td>
                        {{ sec2hms(line.raw.calc.total_failure_stop_seconds) }}
                      </td>
                      <td>{{ sec2hms(line.raw.adjustment) }}</td>
                      <td>
                        {{
                          ppp(
                            line.raw.calc.total,
                            line.raw.calc.total_failure_stop_seconds
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <div
    class="fullscreen"
    style="top: 190px; right: initial; left: 0; width: 47%; bottom: 42px"
  >
    <div style="width: 100%; height: 100%; overflow: auto">
      <div class="xxx" style="min-width: 640px; height: 100%">
        <transition name="yslideanimation">
          <div style="width: 100%; min-width: 480px" v-show="isShow">
            <div>
              <div
                style="width: 200px; text-align: center"
                class="linkbtn"
                v-on:click="page.inputMode()"
              >
                <i class="far fa-calendar-alt"></i> {{ page.targetYmd }}
                <!-- <input type="text" name="datepicker" id="js-datepicker" /> -->
              </div>
            </div>
            <div class="frame">
              <div class="linetable" style="min-width: 300px">
                <table border="0">
                  <thead>
                    <tr>
                      <th>順位</th>
                      <th>アラームNo</th>
                      <th>停止時間</th>
                      <th>割合</th>
                      <th>回数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!page.isParetoNetwork">
                      <td colspan="5"><SpinLoader /></td>
                    </tr>
                    <template
                      v-for="(pareto, index) in page.paretoHolder.balls"
                      :key="index"
                    >
                      <tr v-on:click="onClickRow(pareto.raw.code)">
                        <td class="linetable_linename">
                          {{ index + 1 }}
                        </td>
                        <td class="linetable_linename">
                          {{ pareto.raw.interlock_message }}
                        </td>
                        <td>
                          {{ sec2hms(pareto.raw.stop) }}
                        </td>
                        <td>{{ pareto.raw.ratio }}％</td>
                        <td>{{ pareto.raw.cnt }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>

  <div
    class="fullscreen"
    style="top: 220px; right: 0; left: initial; width: 47%; bottom: 42px"
  >
    <div style="width: 100%; height: 100%">
      <transition name="fade">
        <div
          style="
            width: 90%;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0 5px 5px 0 rgb(0 0 0 / 25%);
            background: rgba(0, 184, 255, 0.3);
            border: solid 1px rgba(0, 184, 255, 1);
          "
          v-show="isShow"
        >
          <canvas id="myChart" width="100%" height="100%" style=""></canvas>
        </div>
      </transition>
    </div>
  </div>

  <div
    style="position: fixed; bottom: 8px; right: 8px; left: 8px; top: initial"
  >
    <div
      style="
        height: 24px;
        overflow: auto;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 4px;
        font-size: 16px;
      "
    >
      {{ page.selectedMessage() }}
    </div>
  </div>

  <div class="backmenu">
    <div class="backicon" v-on:click="gotoBack()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.yslideanimation-enter-active {
  animation: yslide 0.2s;
}
.yslideanimation-leave-active {
  animation: yslide 0.2s reverse;
}
@keyframes yslide {
  from {
    transform: translateX(-1000px);
  }
  to {
    transform: translateX(0);
  }
}

.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}
@keyframes downslide {
  from {
    transform: translateY(-1000px);
  }
  to {
    transform: translateY(0);
  }
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import SpinLoader from "@/components/SpinLoader";
import DyBackground from "@/components/DyBackground";

import { ParetoPage } from "@/js/page/ParetoPage";

/* global Chart */

/* //global flatpickr */

export default {
  name: "Pareto",
  data() {
    let self = this;
    return {
      page: new ParetoPage(function () {
        self.drawgraph();
      }),
      isShow: false,
    };
  },
  beforeUnmount() {
    this.page.destroy();
  },
  methods: {
    sec2hms(sec) {
      try {
        let ss = Number(sec);
        let h = Math.floor(ss / 60 / 60);
        let m = Math.floor((ss - h * 60 * 60) / 60);
        let s = ss - h * 60 * 60 - m * 60;
        return (
          String(h).padStart(2, "0") +
          ":" +
          String(m).padStart(2, "0") +
          ":" +
          String(s).padStart(2, "0")
        );
      } catch (ex) {
        console.log(ex);
      }
      return "00:00";
    },
    onClickRow(code) {
      console.log(code);
      this.page.selectedAlertCode = code;
    },
    ppp(total, stop) {
      let p = 0;
      try {
        if (total > 1) {
          p = ((total - stop) / total) * 100;
        }
      } catch (ex) {
        console.log(ex);
      }
      return Math.floor(p) + "%";
    },
    makeYmd(y, m, d) {
      return y + "-" + m + "-" + d;
    },
    gotoBack() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        if (self.flineId && self.processId && self.deviceId) {
          self.$router.push({
            name: "Device2d",
            query: {
              fline_id: self.flineId,
              process_id: self.processId,
              device_id: self.deviceId,
            },
          });
          return;
        }

        self.$router.push({ name: "Home" });
      }, 1000 * 0.2);
    },
    gotoHome() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Home" });
      }, 1000 * 0.2);
    },
    gotoLayout(processId) {
      this.$router.push({
        name: "Linelayout",
        query: { process_id: processId, fline_id: this.page.flineId },
      });
    },
    isAlertProcess(processId) {
      console.log(processId);
      console.log(this.page.alertHolder.balls);
      for (let i in this.page.alertHolder.balls) {
        let ball = this.page.alertHolder.balls[i];
        if (ball.raw.process_id == processId) {
          console.log("hit!");
          return true;
        }
      }
      return false;
    },
    ymdhms2md(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        let spss = sps[0].split("-");
        if (spss.length > 1) {
          return spss[1] + "/" + spss[2];
        }
      }

      return "";
    },
    drawgraph() {
      console.log(this.page.paretoHolder);

      let blabels = [];
      for (let i in this.page.paretoHolder.balls) {
        blabels.push(this.page.paretoHolder.balls[i].raw.code.substr(1));
      }
      let times = [];
      let timemax = 20;
      for (let i in this.page.paretoHolder.balls) {
        times.push(this.page.paretoHolder.balls[i].raw.stop);
        let st = Number(this.page.paretoHolder.balls[i].raw.stop);
        if (timemax < st) {
          timemax = st;
        }
      }
      let pss = [];
      let ps = 0;
      for (let i in this.page.paretoHolder.balls) {
        ps += Number(this.page.paretoHolder.balls[i].raw.ratio);
        pss.push(ps);
      }

      Chart.defaults.global.defaultFontSize = 19;
      Chart.defaults.global.defaultFontColor = "#fff";
      Chart.defaults.scale.gridLines.color = "rgba(143, 248, 252, 0.6)";
      var ctx = document.getElementById("myChart").getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: blabels,
          datasets: [
            {
              label: "停止割合積み上げ　",
              type: "line",
              fill: false,
              data: pss,
              borderColor: "rgb(154, 162, 235)",
              yAxisID: "y-axis-1",
            },
            {
              label: "停止時間",
              data: times,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.8)",
              yAxisID: "y-axis-2",
            },
          ],
        },
        options: {
          tooltips: {
            mode: "nearest",
            intersect: false,
          },
          responsive: true,
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                position: "right",
                ticks: {
                  max: 100,
                  min: 0,
                  stepSize: 100,
                },
              },
              {
                id: "y-axis-2",
                type: "linear",
                position: "left",
                ticks: {
                  max: timemax,
                  min: 0,
                  stepSize: 600,
                },
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
          },
        },
      });
    },
  },
  mounted() {
    let flineId = this.$route.query.fline_id;

    this.processId = this.$route.query.process_id;
    this.flineId = this.$route.query.fline_id;
    this.deviceId = this.$route.query.device_id;

    let d = new Date();
    this.page.initialize(
      flineId,
      this.makeYmd(d.getFullYear(), d.getMonth() + 1, d.getDate())
    );
    this.isShow = true;
    // flatpickr("#js-datepicker", {
    //   onChange: function (selectedDates, dateStr, instance) {
    //     console.log(selectedDates + ", " + dateStr + ", " + instance);
    //   },
    //   onValueUpdate: function (selectedDates, dateStr, instance) {
    //     console.log(selectedDates + ", " + dateStr + ", " + instance);
    //   },
    // });
  },
  components: {
    Menu,
    SpinLoader,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
