
let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function User(username = null) {
    this.username = username
}
User.prototype.isAdmin = function () {
    return this.username == "shikoku@blue-i.co.jp";
};


function Auth() {
    this.user = new User();
}
Auth.prototype.login = function (username, password) {
    console.log(password);
    this.user = new User(username);
}

function Rs459() {
    this.auth = new Auth();
    this.test = "123";
}
Rs459.prototype.initialize = function () {
    console.log("koko");
};
Rs459.prototype.url = function () {
    return url;
};

export { Rs459 }
