import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Pareto from '../views/Pareto.vue'
import Line from '../views/Line.vue'
import Linelayout from '../views/Linelayout.vue'
import Device2d from '../views/Device2d.vue'
import Device3d from '../views/Device3d.vue'
import Plan from '../views/Plan.vue'
import Adjustment from '../views/Adjustment.vue'
import Robot from '../views/Robot.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/pareto',
    name: 'Pareto',
    component: Pareto
  },
  {
    path: '/line',
    name: 'Line',
    component: Line
  },
  {
    path: '/robot',
    name: 'Robot',
    component: Robot
  },
  {
    path: '/linelayout',
    name: 'Linelayout',
    component: Linelayout
  },
  {
    path: '/device2d',
    name: 'Device2d',
    component: Device2d
  },
  {
    path: '/device3d',
    name: 'Device3d',
    component: Device3d
  },
  {
    path: '/plan',
    name: 'Plan',
    component: Plan
  },
  {
    path: '/adjustment',
    name: 'Adjustment',
    component: Adjustment
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
