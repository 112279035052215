<template>
  <div class="menu">
    <div class="menu_layout">
      <img
        v-on:click="onClickLogo"
        class="menu_layout__logo"
        src="@/booby/assets/img/bep_logo_white.png"
      />
      <div class="menu_layout__title">{{ title }}</div>
      <div class="menu_layout__auto">
        <div class="menu_layout__time">
          {{ time }}
        </div>
      </div>
      <div class="menu_layout__robot" v-on:click="robot">
        <i class="fas fa-robot"></i>
      </div>
      <div class="menu_layout__alert"><i class="fas fa-bell"></i></div>
      <div class="menu_layout__user">
        <div class="menu_layout__user_name">Y-SK-001</div>
        <div class="menu_layout__user_logout" v-on:click="signout">
          <i class="fas fa-sign-out-alt"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 9999;
}

.menu_layout {
  background: rgba(0, 184, 255, 0.4);
  display: flex;
  padding: 8px;
  font-size: 18px;
  color: #fff;
  user-select: none;
}

.menu_layout__title {
  margin-left: 16px;
}

.menu_layout__auto {
  width: 100%;
  margin: auto;
  text-align: center;
}

.menu_layout__time {
  margin: auto;
  text-overflow: ellipsis;
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.5);
}

.menu_layout__alert {
  padding: 4px;
  font-size: 14px;
  margin-right: 8px;
  color: rgba(255, 0, 0, 0.8);
  pointer-events: initial;
}
.menu_layout__alert:hover {
  color: rgba(255, 0, 0, 1);
}

.menu_layout__robot {
  padding: 4px;
  font-size: 14px;
  margin-right: 8px;
  color: #ffff07;
  pointer-events: initial;
}
.menu_layout__robot:hover {
  color: #dddd07;
}

.menu_layout__user {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 4px;
  font-size: 14px;
  pointer-events: initial;
}

.menu_layout__user_name {
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 8px;
}
.menu_layout__user_logout {
  width: 48px;
  text-align: center;
  border-left: solid 1px #fff;
}

.menu_layout__user_logout:hover {
  background: rgba(255, 0, 0, 0.8);
}

.menu_layout__logo {
  height: 25px;
  pointer-events: initial;
}
</style>

<script>
// @ is an alias to /src

export default {
  name: "Menu",
  data() {
    return {
      time: "",
    };
  },
  methods: {
    onClickLogo() {
      this.$router.push({ name: "Home" });
    },
    nowtime() {
      const date1 = new Date();
      const date2 =
        date1.getFullYear() +
        "/" +
        ("00" + (date1.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + date1.getDate()).slice(-2) +
        " " +
        ("00" + date1.getHours()).slice(-2) +
        ":" +
        ("00" + date1.getMinutes()).slice(-2) +
        ":" +
        ("00" + date1.getSeconds()).slice(-2);
      return date2;
    },
    signout() {
      this.$router.push({ name: "Login" });
    },
    robot() {
      this.$router.push({ name: "Robot" });
    },
  },
  mounted() {
    const self = this;
    setInterval(function () {
      self.time = self.nowtime();
    }, 1000);
    self.time = self.nowtime();
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>