function Ball(raw = []) {
    this.raw = raw;
}

function BallsHolder(balls = []) {
    this.balls = balls;
}
BallsHolder.prototype.refresh = function (newBalls = [], key) {
    //update
    console.log(key);
    for (let i in newBalls) {
        let b = newBalls[i].raw;
        if (key in b) {
            for (let j in this.balls) {
                if (key in this.balls[j].raw) {
                    if (this.balls[j].raw[key] == b[key]) {
                        this.balls[j] = newBalls[i];
                    }
                }
            }
        }
    }
    //insert
    for (let i in newBalls) {
        let b = newBalls[i].raw;
        let isExist = false;
        if (key in b) {
            for (let j in this.balls) {
                if (key in this.balls[j].raw) {
                    if (this.balls[j].raw[key] == b[key]) {
                        isExist = true;
                        break;
                    }
                }
            }
        }
        if (!isExist) {
            this.balls.push(newBalls[i]);
        }
    }
    //delete
    for (
        var i = this.balls.length - 1; i >= 0; i--
    ) {
        let b = this.balls[i].raw;
        let isDExist = false;
        if (key in b) {
            for (var x in newBalls) {
                if (key in newBalls[x].raw) {
                    if (newBalls[x].raw[key] == b[key]) {
                        isDExist = true;
                        break;
                    }
                }
            }
        }
        if (isDExist === false) {
            this.balls.splice(i);
        }
    }
    console.log(this.balls);
}


export { Ball, BallsHolder };
