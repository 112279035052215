<template>
  <DyBackground v-if="false" />
  <div class="bgscreen__img"></div>
  <div class="bgscreen"></div>
  <div id="video-area">
    <video
      id="video"
      poster="img/movie.jpg"
      webkit-playsinline
      playsinline
      muted
      autoplay
      loop
    >
      <source
        src="https://bep-eywa.com/admin459/upload/bg.mp4"
        type="video/mp4"
      />
      <p>動画を再生できる環境ではありません。</p>
    </video>
    <!--/video-area-->
  </div>

  <Menu />
  <transition name="downdownanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="screen_title">
        <div class="screen_title__title">四国化工機デモ工場</div>
      </div>
      <div class="frame">
        <table style="width: 100%">
          <tbody>
            <tr>
              <td>
                <div class="card" style="width: 100%">
                  <div class="card_body">
                    <SpinLoader v-if="!page.isInfoNetwork" />
                    <div class="infobox" v-if="page.isInfoNetwork">
                      <div class="infobox__title">
                        <i class="far fa-bell"></i> お知らせ
                      </div>
                      <div class="infobox__up"></div>
                      <div class="infobox__msg">
                        <table>
                          <tbody>
                            <template
                              v-for="(info, index) in page.infoHolder.balls"
                              :key="index"
                            >
                              <tr>
                                <td class="infobox__message_area">
                                  <div class="infobox__message_area_title">
                                    {{ info.raw.information_notified }}
                                  </div>
                                  <div class="infobox__message_area_body">
                                    {{ info.raw.information_message }}
                                  </div>
                                </td>
                                <td class="infobox__icon_area1">
                                  <i class="fas fa-question"></i>
                                </td>
                                <td class="infobox__icon_area2">
                                  <i class="fas fa-check"></i>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div class="infobox__down"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td style="width: 320px; padding: 16px">
                <div class="card">
                  <div class="card_body">
                    <div style="text-align: center">期間限定商品</div>
                    <div style="display: flex; position: relative">
                      <div
                        class="arrow"
                        style="position: absolute; top: 48px; left: -32px"
                        v-on:click="crbefore()"
                      >
                        <img src="img/arrowl.png" />
                      </div>
                      <div
                        class="arrow"
                        style="position: absolute; top: 48px; right: -32px"
                        v-on:click="crnext()"
                      >
                        <img src="img/arrowr.png" />
                      </div>
                      <div style="margin: auto">
                        <img
                          :src="getCr()"
                          width="200"
                          height="150"
                          style="object-fit: contain"
                        />
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </transition>
  <transition name="upupanimation">
    <div class="fullscreen" style="top: 300px" v-show="isShow">
      <div style="width: 100; height: 100%; overflow: auto">
        <div class="frame">
          <div class="scrollarea1">
            <div class="linetable">
              <table border="0">
                <thead>
                  <tr>
                    <th style="width: 30px">No</th>
                    <th style="width: 130px">ライン呼称</th>
                    <th colspan="2" style="width: 210px">生産内容</th>
                    <th colspan="2" style="width: 190px">ステータス</th>
                    <th style="width: 150px">生産品種</th>
                    <th style="width: 70px">賞味期限</th>
                    <th style="width: 90px">指図本数</th>
                    <th style="width: 90px">生産本数</th>
                    <th style="width: 90px">ケース数</th>
                    <th style="width: 110px">生産開始時刻</th>
                    <th style="width: 110px">トラブル停止時間</th>
                    <th style="width: 110px">調整停止時間</th>
                    <th style="width: 110px">生産終了時刻</th>
                    <th>稼働率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!page.isLineNetwork">
                    <td colspan="16"><SpinLoader /></td>
                  </tr>
                  <template
                    v-for="(line, index) in page.lineHolder.balls"
                    :key="index"
                  >
                    <tr>
                      <td>{{ index + 1 }}</td>
                      <td class="linetable_linename">
                        {{ line.raw.fline_name }}
                      </td>
                      <td class="linetable_link">
                        <div
                          class="linkbtn"
                          v-on:click="gotoPlans(line.raw.id)"
                        >
                          <i class="fas fa-clipboard-list"></i> 計画
                        </div>
                      </td>
                      <td class="linetable_link">
                        <div
                          class="linkbtn"
                          v-on:click="gotoLines(line.raw.id)"
                        >
                          <i class="fas fa-tachometer-alt"></i> 工程
                        </div>
                      </td>
                      <td
                        class="linetable_linestatus"
                        v-bind:class="[
                          line.raw.isalert
                            ? 'linetable_linestatus__danger'
                            : 'linetable_linestatus__success',
                        ]"
                      >
                        {{ status2name(line.raw.adjuststatus) }}
                      </td>
                      <td
                        class="linetable_linestatus"
                        v-bind:class="[
                          line.raw.isalert
                            ? 'linetable_linestatus__danger'
                            : 'linetable_linestatus__success',
                        ]"
                      >
                        {{ line.raw.isalert ? "異常" : "正常" }}
                      </td>
                      <td class="linetable_linekind" style="position: relative">
                        <div
                          class="brand_label"
                          style="
                            position: absolute;
                            text-align: center;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 2;
                          "
                        >
                          <div style="background: rgba(0, 0, 0, 0.5)">
                            {{ line.raw.plan.brand_name }}
                          </div>
                        </div>
                        <img
                          :src="brandimgpath(line.raw.plan.brand_image)"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            height: 80px;
                            margin: auto;
                            right: 0;
                          "
                        />
                      </td>
                      <td>{{ ymdhms2md(line.raw.plan.plan_expiry_date) }}</td>
                      <td>{{ comma(line.raw.plan.plan_target) }}</td>
                      <td>
                        {{ comma(line.raw.calc.total_production_quantity) }}
                      </td>
                      <td>
                        {{
                          comma(ccalc(line.raw.calc.total_production_quantity))
                        }}
                      </td>
                      <td>{{ ymdhms2hms(line.raw.plan.plan_start_time) }}</td>
                      <td>
                        {{ sec2hms(line.raw.calc.total_failure_stop_seconds) }}
                      </td>
                      <td>{{ sec2hms(line.raw.adjustment) }}</td>
                      <td>{{ ymdhms2hms(line.raw.plan.plan_end_time) }}</td>
                      <td>
                        {{
                          ppp(
                            line.raw.calc.total,
                            line.raw.calc.total_failure_stop_seconds
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.arrow {
  opacity: 0.7;
}
.arrow:hover {
  opacity: 1;
}
</style>

<style scoped>
#video-area {
  position: fixed;
  z-index: -1; /*最背面に設定*/
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

#video {
  /*天地中央配置*/
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*縦横幅指定*/
  width: 177.77777778vh; /* 16:9 の幅→16 ÷ 9＝ 177.77% */
  height: 56.25vw; /* 16:9の幅 → 9 ÷ 16 = 56.25% */
  min-height: 100%;
  min-width: 100%;
}

.bgscreen__img {
  z-index: -2;
}

.bgscreen {
  z-index: -2;
}

.infobox__title {
  font-size: 20px;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.upanimation-enter-active {
  animation: upslide 0.2s;
}
.upanimation-leave-active {
  animation: upslide 0.2s reverse;
}

@keyframes upslide {
  from {
    transform: translateY(200px);
  }
  to {
    transform: translateY(0);
  }
}

.upupanimation-enter-active {
  animation: upslide 0.2s;
}
.upupanimation-leave-active {
  animation: upupslide 0.2s;
}

@keyframes upupslide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-700px);
  }
}

.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}

.downdownanimation-enter-active {
  animation: downslide 0.2s;
}
.downdownanimation-leave-active {
  animation: downdownslide 0.2s;
}

.downfadeanimation-enter-active {
  animation: downslide 0.2s;
}
.downfadeanimation-leave-active {
  transition: opacity 0.2s ease;
}
.downfadeanimation-leave-to {
  opacity: 0;
}

@keyframes downslide {
  from {
    transform: translateY(-200px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes downdownslide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-700px);
  }
}

tr:hover .brand_label {
  display: none;
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import SpinLoader from "@/components/SpinLoader";

import DyBackground from "@/components/DyBackground";

import { HomePage } from "@/js/page/HomePage";

export default {
  name: "Home",
  data() {
    return {
      page: new HomePage(),
      isShow: false,
      crindex: 0,
      crs: [
        "https://bep-eywa.com/kk459admin/upload/recommend/1_001.png",
        "https://bep-eywa.com/kk459admin/upload/recommend/1_002.png",
        "https://bep-eywa.com/kk459admin/upload/recommend/1_003.png",
        "https://bep-eywa.com/kk459admin/upload/recommend/1_004.png",
      ],
    };
  },
  methods: {
    getCr() {
      return this.crs[this.crindex];
    },
    crbefore() {
      this.crindex--;
      if (this.crindex < 0) {
        this.crindex = this.crs.length - 1;
      }
    },
    crnext() {
      this.crindex++;
      if (this.crindex >= this.crs.length) {
        this.crindex = 0;
      }
    },
    brandimgpath(file) {
      return this.rs459.url() + "/kk459admin/upload/brand/" + file;
    },
    sec2hms(sec) {
      try {
        let ss = Number(sec);
        let h = Math.floor(ss / 60 / 60);
        let m = Math.floor((ss - h * 60 * 60) / 60);
        let s = ss - h * 60 * 60 - m * 60;
        return (
          String(h).padStart(2, "0") +
          ":" +
          String(m).padStart(2, "0") +
          ":" +
          String(s).padStart(2, "0")
        );
      } catch (ex) {
        console.log(ex);
      }
      return "00:00";
    },
    comma(num) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    status2name(id) {
      switch (id) {
        case 0:
          return "生産なし";
        case 1:
          return "生産中";
        case 2:
          return "調整中";
        case 10:
          return "生産終了";
      }
      return;
    },
    gotoLines(id) {
      console.log(id);
      this.page.destroy();
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Line", query: { fline_id: id } });
      }, 1000 * 0.2);
    },
    gotoPlans(id) {
      console.log(id);
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Plan", query: { fline_id: id } });
      }, 1000 * 0.2);
    },
    ymdhms2ymd(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        return sps[0];
      }

      return "";
    },
    ymdhms2md(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        let spss = sps[0].split("-");
        if (spss.length > 1) {
          return spss[1] + "/" + spss[2];
        }
      }

      return "";
    },
    ymdhms2hms(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        if (sps.length > 1) {
          return sps[1];
        }
      }

      return "";
    },
    ppp(total, stop) {
      if (total == 0) {
        return 0;
      }
      let p = 0;
      try {
        if (total > 1) {
          p = ((total - stop) / total) * 100;
        }
      } catch (ex) {
        console.log(ex);
      }
      return Math.floor(p) + "%";
    },
    ccalc(count) {
      try {
        return Math.floor(count / 12);
      } catch (ex) {
        console.log(ex);
      }

      return 0;
    },
  },
  mounted() {
    this.isShow = true;
    this.page.initialize();
    let self = this;
    setInterval(function () {
      self.crnext();
    }, 1000 * 3);
  },
  beforeUnmount() {
    this.isShow = false;
    this.page.destroy();
  },
  components: {
    Menu,
    DyBackground,
    SpinLoader,
  },
  props: {
    rs459: Object,
  },
};
</script>
