
import { Ball, BallsHolder } from "@/js/com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function ProcessStatus(
    id = 0,
    total_production_quantity = 0,
    total_bad_bottle_quantity = 0
) {
    this.id = id;
    this.total_production_quantity = total_production_quantity;
    this.total_bad_bottle_quantity = total_bad_bottle_quantity;
}

function Device(id, name = "", type = 0, timestamp = null) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.timestamp = timestamp;
}

function LinePage() {
    this.name = "line";
    this.flineId = 0;
    this.devices = [];
    this.processHolder = new BallsHolder();
    this.lineHolder = new BallsHolder();
    this.isDestroy = false;
    this.alertHolder = new BallsHolder();
    this.pStatusHolder = new BallsHolder();
    this.ProcessStatus = new ProcessStatus();
    this.isLineNetwork = false;
    this.isDeviceNetwork = false;
}
LinePage.prototype.destroy = function () {
    this.isDestroy = true;
};
LinePage.prototype.initialize = function (flineId) {
    this.flineId = flineId;
    this.isLineNetwork = false;
    this.isDeviceNetwork = false;
    this.loadDevices();
    this.loadLines();
    this.loadAlerts();
    this.loadProcessStatus();
};
LinePage.prototype.loadDevices = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    self.devices = [];
    self.devices.push(
        new Device(1, "せいりつき", "その１のエラー", 1, new Date().toString()),
        new Device(2, "ちょうせいき", "その１のエラー", 1, new Date().toString())
    );

    fetch(
        url +
        "/kk459admin/api/v0/process/search?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);

            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.processHolder.refresh(balls, "id");
            self.isDeviceNetwork = true;
            self.loadDeviceLooper();
        })
        .catch((error) => {
            console.log(error);
            self.loadDeviceLooper(10);
        });
};
LinePage.prototype.loadDeviceLooper = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadDevices();
    }, 1000 * 1);
};
LinePage.prototype.loadLines = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/lines/status?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");
            self.isLineNetwork = true;
            self.loadLineLooper();
        })
        .catch((error) => {
            console.log(error);
            self.loadLineLooper(10);
        });
};
LinePage.prototype.loadLineLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadLines();
    }, 1000 * sec);
};
LinePage.prototype.loadAlerts = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/alert/search?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);

            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.alertHolder.refresh(balls, "id");

            self.loadAlertLooper();
        })
        .catch((error) => {
            console.log(error);
            self.loadAlertLooper(10);
        });
};
LinePage.prototype.loadAlertLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadAlerts();
    }, 1000 * sec);
};

LinePage.prototype.loadProcessStatus = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/lines/processes?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);

            if (json.data) {
                self.ProcessStatus = new ProcessStatus(
                    json.data.id,
                    json.data.total_production_quantity,
                    json.data.total_bad_bottle_quantity
                );
            }

            self.loadProcessStatusLooper();
        })
        .catch((error) => {
            console.log(error);
            self.loadProcessStatusLooper(10);
        });
};
LinePage.prototype.loadProcessStatusLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadProcessStatus();
    }, 1000 * sec);
};

LinePage.prototype.brandthm = function () {
    for (let i in this.lineHolder.balls) {
        let line = this.lineHolder.balls[i];
        return url + "/kk459admin/upload/brand/" + line.raw.plan.brand_image;
    }

    return "";
};


export { LinePage }
