<template>
  <DyBackground />

  <div>生産管理：ライン</div>
  <Menu />

  <transition name="downanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="frame">
        <div class="yoko">
          <div style="width: 100%; overflow: auto">
            <div class="linetable" style="min-width: 900px">
              <table border="0">
                <thead>
                  <tr>
                    <th>ライン呼称</th>
                    <th>ステータス</th>
                    <th>生産品種</th>
                    <th>賞味期限</th>
                    <th>指図本数</th>
                    <th>生産本数</th>
                    <th>ケース数</th>
                    <th>稼働率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!page.isLineNetwork">
                    <td colspan="8"><SpinLoader /></td>
                  </tr>
                  <template
                    v-for="(line, index) in page.lineHolder.balls"
                    :key="index"
                  >
                    <tr>
                      <td class="linetable_linename">
                        {{ line.raw.fline_name }}
                      </td>
                      <td
                        class="linetable_linestatus"
                        v-bind:class="[
                          line.raw.isalert
                            ? 'linetable_linestatus__danger'
                            : 'linetable_linestatus__success',
                        ]"
                      >
                        {{ line.raw.isalert ? "異常" : "正常" }}
                      </td>
                      <td class="linetable_linekind">
                        {{ line.raw.plan.brand_name }}
                      </td>
                      <td>{{ ymdhms2md(line.raw.plan.plan_expiry_date) }}</td>
                      <td>{{ comma(line.raw.plan.plan_target) }}</td>
                      <td>
                        {{ comma(line.raw.calc.total_production_quantity) }}
                      </td>
                      <td>
                        {{
                          comma(ccalc(line.raw.calc.total_production_quantity))
                        }}
                      </td>
                      <td>
                        {{
                          ppp(
                            line.raw.calc.total,
                            line.raw.calc.total_failure_stop_seconds
                          )
                        }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="imagebox" v-if="page.brandthm().length">
            <img class="brandimg" :src="page.brandthm()" />
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="upanimation">
    <div class="fullscreen" style="top: 190px" v-show="isShow">
      <div style="width: 100%; height: 100%; overflow: auto">
        <div class="xxx" style="min-width: 1024px">
          <div style="margin-top: 48px" v-if="!page.isDeviceNetwork">
            <SpinLoader />
          </div>
          <div class="yoko" v-if="page.isDeviceNetwork">
            <template
              v-for="(work, index) in page.processHolder.balls"
              :key="index"
            >
              <div class="koutei">
                <div class="work">{{ work.raw.work_name }}</div>
                <div>
                  <template
                    v-for="(process, index) in work.raw.process"
                    :key="index"
                  >
                    <div style="margin-top: 16px">
                      <div class="card" v-on:click="gotoLayout(process.id)">
                        <div class="card_header">
                          <div style="display: flex">
                            <div
                              class="statmark"
                              style="margin-top: 5px; margin-right: 5px"
                              v-bind:class="[
                                isAlertProcess(process.id)
                                  ? 'statmark--alert'
                                  : 'statmark--success',
                              ]"
                            ></div>
                            <div>
                              {{ process.process_name }}
                            </div>
                          </div>
                        </div>
                        <div class="card_body process_body">
                          <table style="width: 100%" v-if="process.id == 4">
                            <tbody>
                              <tr>
                                <td>生産数</td>
                                <td>
                                  {{
                                    comma(
                                      page.ProcessStatus
                                        .total_production_quantity
                                    )
                                  }}
                                </td>
                                <td>本</td>
                              </tr>
                              <tr>
                                <td>不良率</td>
                                <td>
                                  {{
                                    pt(
                                      page.ProcessStatus
                                        .total_production_quantity,
                                      page.ProcessStatus
                                        .total_bad_bottle_quantity
                                    )
                                  }}
                                </td>
                                <td>％</td>
                              </tr>
                            </tbody>
                          </table>

                          <table style="width: 100%" v-if="process.id != 4">
                            <tbody>
                              <tr>
                                <td>生産数</td>
                                <td>0</td>
                                <td>本</td>
                              </tr>
                              <tr>
                                <td>不良率</td>
                                <td>0</td>
                                <td>％</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="backmenu">
    <div class="backicon" v-on:click="gotoHome()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.upanimation-enter-active {
  animation: upslide 0.2s;
}
.upanimation-leave-active {
  animation: upslide 0.2s reverse;
}

@keyframes upslide {
  from {
    transform: translateY(1000px);
  }
  to {
    transform: translateY(0);
  }
}

.upupanimation-enter-active {
  animation: upslide 0.2s;
}
.upupanimation-leave-active {
  animation: upupslide 0.2s;
}

@keyframes upupslide {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-700px);
  }
}

.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}

.downfadeanimation-enter-active {
  animation: downslide 0.2s;
}
.downfadeanimation-leave-active {
  transition: opacity 0.2s ease;
}
.downfadeanimation-leave-to {
  opacity: 0;
}

@keyframes downslide {
  from {
    transform: translateY(-800px);
  }
  to {
    transform: translateY(0);
  }
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import DyBackground from "@/components/DyBackground";

import SpinLoader from "@/components/SpinLoader";

import { LinePage } from "@/js/page/LinePage";

export default {
  name: "Line",
  data() {
    return {
      page: new LinePage(),
      isShow: false,
    };
  },
  methods: {
    comma(num) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    gotoHome() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Home" });
      }, 1000 * 0.2);
    },
    gotoLayout(processId) {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({
          name: "Linelayout",
          query: { process_id: processId, fline_id: self.page.flineId },
        });
      }, 1000 * 0.2);
    },
    isAlertProcess(processId) {
      console.log(processId);
      console.log(this.page.alertHolder.balls);
      for (let i in this.page.alertHolder.balls) {
        let ball = this.page.alertHolder.balls[i];
        if (ball.raw.process_id == processId) {
          console.log("hit!");
          return true;
        }
      }
      return false;
    },
    ymdhms2md(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        let spss = sps[0].split("-");
        if (spss.length > 1) {
          return spss[1] + "/" + spss[2];
        }
      }

      return "";
    },
    pt(total, bad) {
      if (total == 0) {
        return 0;
      }

      try {
        return Math.floor(bad / total);
      } catch (ex) {
        console.log(ex);
      }
      return 0;
    },
    ppp(total, stop) {
      if (total == 0) {
        return 0;
      }
      let p = 0;
      try {
        if (total > 1) {
          p = ((total - stop) / total) * 100;
        }
      } catch (ex) {
        console.log(ex);
      }
      return Math.floor(p) + "%";
    },
    ccalc(count) {
      try {
        return Math.floor(count / 12);
      } catch (ex) {
        console.log(ex);
      }

      return 0;
    },
  },
  mounted() {
    this.isShow = true;
    let flineId = this.$route.query.fline_id;
    this.page.initialize(flineId);
  },
  beforeUnmount() {
    this.page.destroy();
  },
  components: {
    Menu,
    SpinLoader,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
