<template>
  <DyBackground />

  <div>生産管理：ライン 機器</div>
  <Menu />

  <div class="fullscreen">
    <div class="frame">
      <div style="display: flex; position: relative">
        <div
          class="arrow"
          style="position: absolute; top: 64px; left: -8px"
          v-on:click="crbefore()"
        >
          <img src="img/arrowl.png" />
        </div>

        <transition name="ymslideanimation">
          <div
            style="
              width: 100%;
              margin-right: 32px;
              margin-left: 16px;
              overflow: auto;
            "
            v-show="isShow"
          >
            <div class="screen_title">
              <div class="screen_title__title">
                {{ linename() }} / {{ processname() }}
              </div>
            </div>

            <div class="linetable" style="min-width: 500px">
              <div style="display: flex">
                <div class="devchange" v-if="false">
                  <i class="fas fa-chevron-left"></i>
                </div>
                <table border="0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>機種</th>
                      <th>号機</th>
                      <th>ステータス</th>
                      <th>メーカ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!page.isMachineNetwork">
                      <td colspan="5"><SpinLoader /></td>
                    </tr>
                    <tr v-if="page.isMachineNetwork">
                      <td>
                        <div
                          class="statmark"
                          v-bind:class="[
                            page.alertHolder.balls.length > 0
                              ? 'statmark--alert'
                              : 'statmark--success',
                          ]"
                          style="margin: auto"
                        ></div>
                      </td>
                      <td>{{ page.machine.name }}</td>
                      <td>{{ page.machine.deployname }}</td>
                      <td>
                        {{
                          page.alertHolder.balls.length > 0
                            ? "異常あり"
                            : "正常"
                        }}
                      </td>
                      <td>{{ page.machine.makername }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="devchange" v-if="false">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="downanimation">
          <div style="width: 100%; position: relative" v-show="isShow">
            <div
              class="arrow"
              style="position: absolute; top: 64px; left: -56px"
              v-on:click="crbefore()"
            >
              <img src="img/arrowr.png" />
            </div>

            <div class="card card--danger">
              <div class="card_header card_header--danger">
                <i class="far fa-bell"></i> アラームリスト
              </div>
              <div class="card_body">
                <SpinLoader v-if="!page.isAlertNetwork" />
                <div class="infobox" v-if="page.isAlertNetwork">
                  <div class="infobox__up"></div>
                  <div class="infobox__msg">
                    <table style="width: 100%">
                      <tbody>
                        <template
                          v-for="(alert, index) in page.alertHolder.balls"
                          :key="index"
                        >
                          <tr>
                            <td class="infobox__message_area">
                              <div class="infobox__message_area_title">
                                {{ alert.raw.test_start }}
                              </div>
                              <div class="infobox__message_area_body">
                                {{ alert.raw.interlock_message }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div class="infobox__down"></div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="frame" v-show="isShow">
      <div style="display: flex">
        <div style="width: 100%"></div>
      </div>
    </div>
  </div>

  <div
    class="fullscreen"
    style="top: 260px; left: 16px; right: 0px; bottom: 16px"
  >
    <div style="display: flex; height: 100%; width: 100%">
      <transition name="fade">
        <div style="width: 100%; position: relative" v-show="isShow">
          <div id="map"></div>
          <div
            style="position: absolute; bottom: 0; width: 100%; z-index: 9999"
          >
            <div style="max-width: 300px; margin: auto; display: flex">
              <div
                class="mapchange linkicon"
                style="margin-left: 16px"
                v-on:click="preBluePrint()"
              >
                <i class="fas fa-chevron-circle-left"></i>
              </div>
              <div style="width: 100%"></div>
              <div
                class="mapchange linkicon"
                style="margin-right: 16px"
                v-on:click="nextBluePrint()"
              >
                <i class="fas fa-chevron-circle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="yslideanimation">
        <div style="width: 480px; padding: 16px" v-show="isShow">
          <SpinLoader v-if="!page.isProcessNetwork" />
          <template
            v-for="(process, index) in page.processHolder.balls"
            :key="index"
          >
            <div class="frame">
              <div class="card">
                <div class="card_header">
                  <i class="far fa-bell"></i> 充填機
                </div>
                <div class="card_body process_body">
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>製品カウンタ</td>
                        <td>
                          {{
                            comma(process.raw.controller_production_quantity)
                          }}
                        </td>
                        <td>本</td>
                      </tr>
                      <tr>
                        <td>ノーキャップカウンタ</td>
                        <td>
                          {{ comma(process.raw.controller_no_cap_quantity) }}
                        </td>
                        <td>本</td>
                      </tr>
                      <tr>
                        <td>充填不良カウンタ</td>
                        <td>
                          {{
                            comma(process.raw.controller_poor_filling_quantity)
                          }}
                        </td>
                        <td>本</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
          <div
            style="text-align: center; margin-top: 16px"
            class="linkbtn"
            v-on:click="gotoPareto()"
          >
            <i class="fas fa-clipboard-list"></i> トラブルパレート図
          </div>
          <div
            style="text-align: center; margin-top: 16px"
            class="linkbtn"
            v-on:click="gotoRobot()"
          >
            <i class="fas fa-robot"></i> ロボット点検
          </div>
        </div>
      </transition>
    </div>
  </div>

  <div class="backmenu">
    <div class="backicon" v-on:click="gotoLayout()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.arrow {
  opacity: 0.7;
}
.arrow:hover {
  opacity: 1;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.downanimation-enter-active {
  animation: downslide 0.2s;
}
.downanimation-leave-active {
  animation: downslide 0.2s reverse;
}
@keyframes downslide {
  from {
    transform: translateY(-1000px);
  }
  to {
    transform: translateY(0);
  }
}

.yslideanimation-enter-active {
  animation: yslide 0.2s;
}
.yslideanimation-leave-active {
  animation: yslide 0.2s reverse;
}
@keyframes yslide {
  from {
    transform: translateX(2000px);
  }
  to {
    transform: translateX(0);
  }
}

.ymslideanimation-enter-active {
  animation: ymslide 0.2s;
}
.ymslideanimation-leave-active {
  animation: ymslide 0.2s reverse;
}
@keyframes ymslide {
  from {
    transform: translateX(-2000px);
  }
  to {
    transform: translateX(0);
  }
}

.upanimation-enter-active {
  animation: upslide 0.2s;
}
.upanimation-leave-active {
  animation: upslide 0.2s reverse;
}

@keyframes upslide {
  from {
    transform: translateY(600px);
  }
  to {
    transform: translateY(0);
  }
}
</style>

<style scoped>
.statmark {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.linetable {
  min-width: initial;
}

.linetable td {
  padding: 24px 8px;
  text-align: center;
}
</style>

<style scoped>
.infobox__msg {
  height: 128px;
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import SpinLoader from "@/components/SpinLoader";
import DyBackground from "@/components/DyBackground";

import { Device2DPage } from "@/js/page/Device2DPage";

/* global L */

export default {
  name: "Device2d",
  data() {
    let self = this;
    return {
      page: new Device2DPage(
        function () {
          self.mapdraw();
          self.markerdraw();
        },
        function () {
          self.markerdraw();
        },
        function () {},
        function () {
          self.mapdraw();
          self.markerdraw();
        }
      ),
      baseMapObject: null,
      markerObjects: [],
      isShow: false,
    };
  },
  methods: {
    comma(num) {
      return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },
    processname() {
      if (this.page.processHolder) {
        if (this.page.processHolder.balls) {
          if (this.page.processHolder.balls.length > 0) {
            return "充填機";
          }
        }
      }

      return "";
    },
    linename() {
      if (this.page.lineHolder) {
        if (this.page.lineHolder.balls) {
          if (this.page.lineHolder.balls.length > 0) {
            return this.page.lineHolder.balls[0].raw.fline_name;
          }
        }
      }

      return "";
    },
    gotoDevice3d() {
      this.$router.push({ name: "Device3d", query: { id: this.page.id } });
    },
    gotoLine() {
      this.$router.push({ name: "Line", query: { id: this.page.getLineId() } });
    },
    gotoPareto() {
      let self = this;
      this.isShow = false;

      setTimeout(function () {
        self.$router.push({
          name: "Pareto",
          query: {
            fline_id: self.page.flineId,
            process_id: self.page.processId,
            device_id: self.page.deviceId,
          },
        });
      }, 1000 * 0.2);
    },
    gotoRobot() {
      let self = this;
      this.isShow = false;

      setTimeout(function () {
        self.$router.push({
          name: "Robot",
          query: {
            fline_id: self.page.flineId,
            process_id: self.page.processId,
            device_id: self.page.deviceId,
          },
        });
      }, 1000 * 0.2);
    },
    gotoLayout() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({
          name: "Linelayout",
          query: {
            fline_id: self.page.flineId,
            process_id: self.page.processId,
          },
        });
      }, 1000 * 0.2);
    },
    searchErrorBluePrint() {
      this.page.searchErrorBluePrint();
      this.mapdraw();
      this.markerdraw();
    },
    nextBluePrint() {
      this.page.nextBlueprint();
      this.mapdraw();
      this.markerdraw();
    },
    preBluePrint() {
      this.page.preBlueprint();
      this.mapdraw();
      this.markerdraw();
    },
    markerdraw() {
      if (this.markerObjects) {
        for (let ii in this.markerObjects) {
          let m = this.markerObjects[ii];
          m.setIcon(
            L.divIcon({
              className: "marker--standby",
            })
          );
        }

        for (let i in this.markerObjects) {
          let marker = this.markerObjects[i];
          for (let key in this.page.alertHolder.balls) {
            let alert = this.page.alertHolder.balls[key];
            if (marker.aposition_id == alert.raw.aposition_id) {
              marker.setIcon(
                L.divIcon({
                  className: "marker--danger",
                })
              );
              break;
            }
          }
        }
      }
    },
    mapdraw() {
      let bp = this.page.selectedBlueprint();
      console.log(bp.image);
      var image = {
        url: this.rs459.url() + "/kk459admin/upload/blueprint/" + bp.image,
        width: bp.width,
        height: bp.height,
      };

      var imageBounds = L.latLngBounds(
        [0, 0],
        [image.height / 10, image.width / 10]
      );

      this.vmap.fitBounds(imageBounds);

      if (this.baseMapObject) {
        this.vmap.removeLayer(this.baseMapObject);
      }
      this.baseMapObject = null;

      this.baseMapObject = L.imageOverlay(image.url, imageBounds);
      this.baseMapObject.addTo(this.vmap);

      for (let i in this.markerObjects) {
        this.vmap.removeLayer(this.markerObjects[i]);
      }
      this.markerObjects = [];

      for (let i in bp.apositions) {
        let ap = bp.apositions[i];
        console.log(ap);
        // let m = L.marker([ap.y / 10, ap.x / 10]);
        // m.addTo(this.vmap);
        var marker = L.marker([ap.y / 10, ap.x / 10], {
          icon: L.divIcon({
            className: "marker--standby",
          }),
        });
        marker.addTo(this.vmap);
        marker.aposition_id = ap.id;
        this.markerObjects.push(marker);
        console.log(marker);

        this.vmap.invalidateSize();
      }
    },
  },
  mounted() {
    let processId = this.$route.query.process_id;
    let flineId = this.$route.query.fline_id;
    let deviceId = this.$route.query.device_id;
    this.page.initialize(deviceId, processId, flineId);

    let map = L.map("map", {
      crs: L.CRS.Simple,
      zoomAnimation: false,
    });
    this.vmap = map;
    this.isShow = true;
    //this.mapdraw();
  },
  beforeUnmount() {
    this.page.destroy();

    if (this.baseMapObject) {
      this.vmap.removeLayer(this.baseMapObject);
    }
    for (let i in this.markerObjects) {
      this.vmap.removeLayer(this.markerObjects[i]);
    }
  },
  components: {
    Menu,
    SpinLoader,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
