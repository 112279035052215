<template>
  <DyBackground />
  <div>トラブルパレート図</div>
  <Menu />

  <transition name="yyslideanimation">
    <div class="fullscreen" v-show="isShow">
      <div class="frame">
        <div style="width: 100%; overflow: auto">
          <div class="linetable">
            <table border="0">
              <thead>
                <tr>
                  <th>予定日付</th>
                  <th>予定開始時間</th>
                  <th>予定終了時間</th>
                  <th>生産品種</th>
                  <th>目標</th>
                  <th>生産開始時間</th>
                  <th>生産終了時間</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!page.isPlanNetwork">
                  <td colspan="7"><SpinLoader /></td>
                </tr>

                <template
                  v-for="(plan, index) in page.plansHolder.balls"
                  :key="index"
                >
                  <tr>
                    <td>
                      {{ ymdhms2ymd(plan.raw.plan_start_schedule) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_start_schedule) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_end_schedule) }}
                    </td>
                    <td>{{ plan.raw.brand_name }}</td>
                    <td>
                      {{ plan.raw.plan_target }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_start_time) }}
                    </td>
                    <td>
                      {{ ymdhms2hms(plan.raw.plan_end_time) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <transition name="yslideanimation">
    <div class="fullscreen" style="top: 200px" v-show="isShow">
      <div style="width: 100%; height: 100%; overflow: auto">
        <div class="frame">
          <div class="linetable">
            <table border="0">
              <thead>
                <tr>
                  <th>調整開始時間</th>
                  <th>調整終了時間</th>
                  <th colspan="2">コントローラ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!page.isPlanNetwork">
                  <td colspan="4"><SpinLoader /></td>
                </tr>
                <template
                  v-for="(plan, index) in page.plansHolder.balls"
                  :key="index"
                >
                  <template
                    v-for="(adjustment, aindex) in plan.raw.adjustment"
                    :key="aindex"
                  >
                    <tr>
                      <td>
                        {{ adjustment.adjustment_start_time }}
                      </td>
                      <td>
                        {{ adjustment.adjustment_end_time }}
                      </td>
                      <td class="linetable_link">
                        <div
                          class="linkbtn"
                          v-on:click="endAdjustment(adjustment.id)"
                        >
                          <i class="fas fa-clipboard-list"></i> 終了
                        </div>
                      </td>
                      <td class="linetable_link">
                        <div
                          class="linkbtn"
                          v-on:click="deleteAdjustment(adjustment.id)"
                        >
                          <i class="fas fa-clipboard-list"></i> 削除
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="frame">
          <div class="linetable_link" style="width: 200px; text-align: center">
            <div class="linkbtn" v-on:click="regist()">
              <i class="fas fa-clipboard-list"></i> 調整開始
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <div class="backmenu">
    <div class="backicon" v-on:click="gotoPlan()">
      <i class="fas fa-arrow-alt-circle-left"></i>
    </div>
  </div>
</template>

<style scoped>
.yslideanimation-enter-active {
  animation: yslide 0.2s;
}
.yslideanimation-leave-active {
  animation: yslide 0.2s reverse;
}

@keyframes yslide {
  from {
    transform: translateX(2000px);
  }
  to {
    transform: translateX(0);
  }
}

.yyslideanimation-enter-active {
  animation: yslide 0.2s;
}
.yyslideanimation-leave-active {
  animation: yyslide 0.2s;
}

@keyframes yyslide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-2000px);
  }
}
</style>

<script>
// @ is an alias to /src
import Menu from "@/components/Menu";
import SpinLoader from "@/components/SpinLoader";
import DyBackground from "@/components/DyBackground";

import { AdjustmentPage } from "@/js/page/AdjustmentPage";

export default {
  name: "Adjustment",
  data() {
    return {
      page: new AdjustmentPage(),
      isShow: false,
    };
  },
  methods: {
    gotoHome() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({ name: "Home" });
      }, 1000 * 0.2);
    },
    gotoPlan() {
      let self = this;
      this.isShow = false;
      setTimeout(function () {
        self.$router.push({
          name: "Plan",
          query: { fline_id: self.page.flineId },
        });
      }, 1000 * 0.2);
    },
    ymdhms2ymd(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        return sps[0];
      }

      return "";
    },
    ymdhms2hms(ymdhms = null) {
      if (ymdhms) {
        let sps = ymdhms.split(" ");
        if (sps.length > 1) {
          return sps[1];
        }
      }

      return "";
    },
    regist() {
      this.page.regist();
    },
    endAdjustment(id) {
      this.page.endAdjustment(id);
    },
    deleteAdjustment(id) {
      this.page.deleteAdjustment(id);
    },
  },
  mounted() {
    let planId = this.$route.query.plan_id;
    let flineId = this.$route.query.fline_id;
    this.page.initialize(planId, flineId);
    this.isShow = true;
  },
  beforeUnmount() {
    this.page.destroy();
  },
  components: {
    Menu,
    SpinLoader,
    DyBackground,
  },
  props: {
    rs459: Object,
  },
};
</script>
