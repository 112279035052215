import { Ball, BallsHolder } from "@/js/com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function Apositions(id, name, x, y) {
    this.id = id;
    this.name = name;
    this.x = x;
    this.y = y;
}

function Blueprint(id, image, width, height, apositions = []) {
    this.id = id;
    this.image = image;
    this.width = width;
    this.height = height;
    this.apositions = apositions;
}

function Machine(
    id,
    name = "",
    makername = "",
    deployname = "",
    blueprints = []
) {
    this.id = id;
    this.name = name;
    this.makername = makername;
    this.deployname = deployname;
    this.blueprints = blueprints;
}

function Device2DPage(
    callback = function () { },
    acallback = function () { },
    pcallback = function () { },
    initcallback = function () { }
) {
    this.deviceId = 0;
    this.processId = 0;
    this.flineId = 0;
    this.isDestroy = false;
    this.machine = new Machine();
    this.callback = callback;
    this.selected_blueprint_id = -1;
    this.alertHolder = new BallsHolder();
    this.acallback = acallback;
    this.pcallback = pcallback;
    this.processHolder = new BallsHolder();
    this.initload = 0;
    this.initcallback = initcallback;
    this.isMachineNetwork = false;
    this.isAlertNetwork = false;
    this.isProcessNetwork = false;
    this.lineHolder = new BallsHolder();
}
Device2DPage.prototype.destroy = function () {
    this.isDestroy = true;
};
Device2DPage.prototype.initialize = function (deviceId, processId, flineId) {
    this.isMachineNetwork = false;
    this.deviceId = deviceId;
    this.processId = processId;
    this.flineId = flineId;
    this.selected_blueprint_id = -1;
    if (this.isDestroy) {
        return;
    }
    this.loadMachineInfo();
    this.loadAlerts();
    this.loadDeploy();
    this.loadLines();
};
Device2DPage.prototype.loadLines = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/lines/status?fline_id=" +
        this.flineId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);
            let balls = [];
            balls.push(new Ball(json.data));
            self.lineHolder.refresh(balls, "id");
            self.isLineNetwork = true;
        })
        .catch((error) => {
            console.log(error);
        });
};

Device2DPage.prototype.selectedBlueprint = function () {
    if (this.selected_blueprint_id > 0) {
        for (let i in this.machine.blueprints) {
            let bp = this.machine.blueprints[i];
            if (bp.id == this.selected_blueprint_id) {
                return bp;
            }
        }
    }

    return new Blueprint();
};
Device2DPage.prototype.nextBlueprint = function () {
    console.log(this.selected_blueprint_id);
    if (this.selected_blueprint_id > 0) {
        let num = -1;
        for (let i = 0; i < this.machine.blueprints.length; i++) {
            let bp = this.machine.blueprints[i];
            if (bp.id == this.selected_blueprint_id) {
                num = i;
                break;
            }
        }
        if (num >= 0) {
            if (this.machine.blueprints.length <= num + 1) {
                this.selected_blueprint_id = this.machine.blueprints[0].id;
            } else {
                this.selected_blueprint_id = this.machine.blueprints[num + 1].id;
            }
        }
    }
};

Device2DPage.prototype.preBlueprint = function () {
    if (this.selected_blueprint_id > 0) {
        let num = -1;
        for (let i = 0; i < this.machine.blueprints.length; i++) {
            let bp = this.machine.blueprints[i];
            if (bp.id == this.selected_blueprint_id) {
                num = i;
                break;
            }
        }
        if (num >= 0) {
            if (num < 1) {
                this.selected_blueprint_id =
                    this.machine.blueprints[this.machine.blueprints.length - 1].id;
            } else {
                this.selected_blueprint_id = this.machine.blueprints[num - 1].id;
            }
        }
    }
};
Device2DPage.prototype.searchErrorBluePrint = function () {
    console.log("yyyyyyyyyyy");
    if (this.initload != 1) {
        return;
    }

    if (this.alertHolder.balls.length > 0) {
        this.selected_blueprint_id = this.alertHolder.balls[0].raw.blueprint_id;
        console.log("zzzzzzzzzzz", this.alertHolder.balls[0].raw.blueprint_id);
        this.initcallback();
    }

    //searchErrorBluePrint

    // console.log(this.selected_blueprint_id);
    // if (this.selected_blueprint_id > 0) {
    //   let num = -1;
    //   for (let i = 0; i < this.machine.blueprints.length; i++) {
    //     let bp = this.machine.blueprints[i];
    //     if (bp.id == this.selected_blueprint_id) {
    //       num = i;
    //       break;
    //     }
    //   }
    //   if (num >= 0) {
    //     if (this.machine.blueprints.length <= num + 1) {
    //       this.selected_blueprint_id = this.machine.blueprints[0].id;
    //     } else {
    //       this.selected_blueprint_id = this.machine.blueprints[num + 1].id;
    //     }
    //   }
    // }
};

Device2DPage.prototype.loadDeploy = function () {
    let self = this;
    fetch(
        url +
        "/kk459admin/api/v0/process/deploy?deploy_id=" +
        this.deviceId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            let process = json.data;
            console.log(process);
            self.isProcessNetwork = true;
            try {
                if (json.data.processdata != false) {
                    let balls = [];
                    balls.push(new Ball(json.data.processdata));
                    self.processHolder.refresh(balls, "id");
                    self.pcallback();
                }
            } catch (ex) {
                console.log(ex);
            }
        })
        .catch((error) => {
            console.log(error);
            self.isProcessNetwork = true;
        });
};

Device2DPage.prototype.loadMachineInfo = function () {
    let self = this;
    fetch(
        url +
        "/kk459admin/api/v0/deploy/detail?deploy_id=" +
        this.deviceId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            let machine = json.data;
            let bps = [];
            for (let bk in machine.blueprints) {
                let bp = machine.blueprints[bk];
                let aps = [];
                for (let ak in bp.apositions) {
                    let ap = bp.apositions[ak];
                    aps.push(
                        new Apositions(
                            ap.id,
                            ap.aposition_name,
                            ap.aposition_x,
                            ap.aposition_y
                        )
                    );
                }

                bps.push(
                    new Blueprint(
                        bp.id,
                        bp.blueprint_image,
                        bp.blueprint_image_width,
                        bp.blueprint_image_height,
                        aps
                    )
                );
            }
            self.machine = new Machine(
                machine.id,
                machine.machine_name,
                machine.machine_maker_name,
                machine.deploy_name,
                bps
            );
            if (self.machine.blueprints.length > 0) {
                self.selected_blueprint_id = machine.blueprints[0].id;
            }
            self.initload = 1;
            self.isMachineNetwork = true;
            console.log(self.machine);
            self.callback();
            //console.log(self.devices);
        })
        .catch((error) => {
            console.log(error);

        });
};
Device2DPage.prototype.getLineId = function () {
    if (this.isDestroy) {
        return;
    }
    return 1;
};
Device2DPage.prototype.loadAlerts = function () {
    let self = this;

    if (this.isDestroy) {
        return;
    }

    fetch(
        url +
        "/kk459admin/api/v0/alert/deploy?deploy_id=" +
        this.deviceId +
        "&dummy=" +
        new Date().getTime() /*'stub/lines.json'*/
    )
        .then((response) => response.json())
        .then((json) => {
            console.log(json);

            self.isAlertNetwork = true;

            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.alertHolder.refresh(balls, "test_code");
            if (self.initload == 1) {
                console.log("zzzzzzzzzzzzzzzzzzzz");
                self.searchErrorBluePrint();
                self.initload = 2;
            }
            self.acallback();

            self.loadAlertLooper();
        })
        .catch((error) => {
            console.log(error);

            self.loadAlertLooper(10);
        });
};
Device2DPage.prototype.loadAlertLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    setTimeout(function () {
        self.loadAlerts();
    }, 1000 * sec);
};

export { Device2DPage }
