import { Ball, BallsHolder } from "../com/ball.js";

let url = "https://bep-eywa.com:8458";
url = "http://localhost";
url = process.env.VUE_APP_KK8458;

function HomePage() {
    this.name = "home";
    this.infoHolder = new BallsHolder();
    this.lineHolder = new BallsHolder();
    this.isDestroy = false;
    this.isInfoNetwork = false;
    this.isLineNetwork = false;
}
HomePage.prototype.destroy = function () {
    this.isDestroy = true;
};
HomePage.prototype.initialize = function () {
    this.isInfoNetwork = false;
    this.isLineNetwork = false;
    this.loadInfos();
    this.loadLines();
};
HomePage.prototype.loadLines = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }

    fetch(url + '/kk459admin/api/v0/lines/statuses?dummy=' + new Date().getTime()/*'stub/lines.json'*/)
        .then(response => response.json())
        .then(json => {

            console.log(json);
            self.isInfoNetwork = true;

            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.lineHolder.refresh(balls, "id");
            self.loadLineLooper();
        }).catch(error => {
            console.log(error);
            self.loadLineLooper(10);
        });
};
HomePage.prototype.loadLineLooper = function (sec = 3) {
    let self = this;
    if (this.isDestroy) {
        return;
    }
    setTimeout(function () {
        self.loadLines();
    }, 1000 * sec);
};
HomePage.prototype.loadInfos = function () {
    let self = this;
    if (this.isDestroy) {
        return;
    }
    self.infos = [];
    fetch(url + '/kk459admin/api/v0/informations/search?dummy=' + new Date().getTime())
        .then(response => response.json())
        .then(json => {
            console.log(json);
            self.isLineNetwork = true;

            let balls = [];
            for (let i in json.data) {
                balls.push(new Ball(json.data[i]));
            }
            self.infoHolder.refresh(balls, "id");
            self.loadInfoLooper();
        }).catch(error => {
            console.log(error);
            self.loadInfoLooper(10);
        });
};
HomePage.prototype.loadInfoLooper = function (sec = 30) {
    let self = this;
    if (this.isDestroy) {
        return;
    }
    setTimeout(function () {
        self.loadInfos();
    }, 1000 * sec);
};


export { HomePage }
